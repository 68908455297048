import { useAuth } from "@features/accounts/hooks/useAuth"
import { useRoute } from "eddev/routing"
import { useEffect } from "react"

export function HubSpotTracking() {
  const email = useAuth((state) => state.user?.email)
  const route = useRoute()

  useEffect(() => {
    try {
      window._hsq?.push([
        "identify",
        {
          email: email,
        },
      ])
    } catch (e) {
      console.error(e)
    }
  }, [email])

  useEffect(() => {
    try {
      window._hsq?.push(["setPath"], route.pathname)
      window._hsq?.push(["trackPageView"])
    } catch (e) {
      console.error(e)
    }
  }, [route.pathname])

  return null
}
declare global {
  interface Window {
    _hsq: any[] | undefined
  }
}
