import { useMemberStatus } from "@hooks/queries"
import { useIsSSR } from "eddev/routing"
import { create } from "zustand"
import { persist } from "zustand/middleware"

type AuthState = {
  name: string
  email: string
  authToken: string
  refreshToken: string
  refreshTimeout?: number
}

type SessionState = {
  sessionToken: string
}

type AuthStore = {
  user?: AuthState
  session?: SessionState
  authPromise: Promise<void> | null
  isLoggedIn(): boolean
  handleLoggedIn: (state: AuthState) => void
  handleSession: (state: SessionState) => void
  logout: () => void
}

export const DEFAULT_AUTH_TOKEN_TIMEOUT = 20 * 60 * 1000

export const useAuth = create<AuthStore>()(
  persist(
    (set, get) => ({
      authPromise: null,
      handleLoggedIn: (user) => {
        set({
          user: {
            ...user,
            refreshTimeout: Date.now() + DEFAULT_AUTH_TOKEN_TIMEOUT,
          },
        })
      },
      handleSession: (session) => {
        set({
          session: session,
        })
      },
      isLoggedIn() {
        return !!get().user
      },
      logout: () => {
        set({ user: undefined, session: undefined })
      },
    }),
    {
      name: "auth-storage",
      partialize: (state) => {
        return {
          user: state.user,
          session: state.session,
        }
      },
    }
  )
)

export function useIsLoggedIn() {
  const isSSR = useIsSSR()
  const actuallyLoggedIn = useAuth((s) => s.isLoggedIn())

  return isSSR ? false : actuallyLoggedIn
}

type MembershipStatus = {
  id?: string
  hasAccess: boolean
  expiresSoon: boolean
  planSlug?: string
  loading: boolean
  memberNumber?: string
  profileUrl?: string
  ownsBusinessId?: number
}

export function useMembershipStatus(): MembershipStatus {
  const ssr = useIsSSR()
  const loggedIn = useIsLoggedIn()
  const status = useMemberStatus(undefined, {
    refetchOnWindowFocus: false,
    enabled: loggedIn,
  })

  const ownsBusiness = status.data?.customer?.user?.id === status.data?.customer?.businessTeam?.owner?.id ? status.data?.customer?.businessTeam?.id : undefined

  if ((status.isLoading && loggedIn) || ssr) {
    return {
      hasAccess: false,
      expiresSoon: false,
      loading: true,
    }
  } else if (loggedIn && status.data?.customer?.memberStatus) {
    return {
      id: status.data.customer.user?.id!,
      profileUrl: status.data.customer.user?.uri!,
      hasAccess: !!status.data?.customer?.memberStatus?.isActive,
      expiresSoon: !!status.data?.customer?.memberStatus?.expiresSoon,
      planSlug: status.data?.customer?.memberStatus?.plan?.slug!,
      loading: false,
      memberNumber: status.data?.customer?.memberStatus?.isActive ? status.data.customer.memberStatus.memberNumber! : undefined,
      ownsBusinessId: ownsBusiness,
    }
  } else {
    return {
      hasAccess: false,
      expiresSoon: false,
      loading: false,
    }
  }
}
